/* eslint-disable @next/next/no-img-element */
import { Dialog, Transition } from '@headlessui/react'
import clsx from 'clsx'
import { Fragment, FunctionComponent } from 'react'
import Slider from 'react-slick'

import { ImageCrop } from 'components/upload'

interface IImageViewer {
  showAllImages: boolean
  closeAllImages: () => void
  images: ImageCrop[]
}

const ImageViewer: FunctionComponent<IImageViewer> = ({
  showAllImages,
  closeAllImages,
  images,
}) => {
  return (
    <Transition appear show={showAllImages} as={Fragment}>
      <Dialog as="div" onClose={closeAllImages}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-60" />
        </Transition.Child>

        <div className="fixed inset-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <Dialog.Panel>
              <Slider
                dots
                infinite
                centerMode
                centerPadding="60px"
                speed={500}
                slidesToShow={1}
                slidesToScroll={1}
                className="top-[10vh]"
              >
                {images?.map((imageData: ImageCrop, idx: number) => {
                  return (
                    <div key={idx}>
                      <div className="flex justify-center w-full">
                        <div className="max-w-[500px]">
                          <img
                            className={clsx(
                              'rounded-md max-h-auto object-cover w-full',
                            )}
                            src={imageData.image}
                            alt="Post Image"
                          />
                        </div>
                      </div>
                    </div>
                  )
                })}
              </Slider>
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  )
}

export default ImageViewer
