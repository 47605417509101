import { TrackedFile } from 'components/upload'

export const onImageUploadStatusChange = (
  trackedFiles: TrackedFile[] | undefined,
  file: TrackedFile,
) => {
  let trackedFilesCopy: TrackedFile[] = []
  if (trackedFiles && trackedFiles.length > 0) {
    trackedFilesCopy = [...trackedFiles]
  }

  const fileIndex = trackedFilesCopy.lastIndexOf(file)
  const isFileTracked = fileIndex !== -1

  if (!isFileTracked) {
    trackedFilesCopy.push(file)
    return trackedFilesCopy
  }

  trackedFilesCopy[fileIndex] = file
  return trackedFilesCopy
}
