import { FunctionComponent } from 'react'

import { Experience } from 'components/profile/profile.interface'
import { Avatar } from 'components/ui'
import { ImageCrop } from 'components/upload'

import PostAvatar from './post_avatar'
import UserInfo from './user_info/user_info'

interface IProfileSection {
  author: any
  releaseDate: Date
  authorFullname: string
  postId: number
  postTitle: string
  currentJob?: string | null
  experiences?: Experience[]
  isAnonymous: boolean
  isEditing?: boolean
}

const ProfileSection: FunctionComponent<IProfileSection> = ({
  author,
  releaseDate,
  authorFullname,
  postId,
  postTitle,
  currentJob,
  experiences,
  isAnonymous,
  isEditing = false,
}) => {
  return (
    <div className="flex flex-wrap gap-2 items-center">
      {isAnonymous && !isEditing ? (
        <div>
          <Avatar
            layout="fill"
            className="object-cover rounded-full"
            alt="Profile image"
            size={16}
          />
        </div>
      ) : (
        <>
          <PostAvatar author={author} />
        </>
      )}
      <UserInfo
        isAnonymous={isAnonymous}
        isEditing={isEditing}
        releaseDate={releaseDate}
        authorFullname={authorFullname}
        authorUsername={author?.username ?? author?.business.profile.id}
        postId={postId}
        title={postTitle}
        currentJob={currentJob}
        experiences={experiences}
        businessType={author?.type ?? undefined}
      />
    </div>
  )
}

export default ProfileSection
