/* eslint-disable @next/next/no-img-element */
import { Dialog, Transition } from '@headlessui/react'
import { XIcon } from '@heroicons/react/outline'
import clsx from 'clsx'
import { Fragment, FunctionComponent, useState } from 'react'

import { Button } from 'components/ui'
import { ImageCrop } from 'components/upload'

export const ImageModal = ({
  imageData,
  isOpen,
  onClose,
}: {
  imageData: ImageCrop
  isOpen: boolean
  onClose: () => void
}) => {
  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" onClose={onClose} className="relative z-50">
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-70" />
        </Transition.Child>

        <div className="overflow-hidden fixed inset-0">
          <button
            onClick={onClose}
            className="absolute right-0 mx-5 mt-5 outline-none focus:outline-0"
          >
            <XIcon className="w-8 h-8 stroke-white" />
          </button>
          <div className="flex justify-center items-center p-4 min-h-full text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="overflow-hidden p-6 w-full max-w-xl text-left align-middle rounded shadow-xl transition-all transform">
                <img
                  className={clsx('max-h-auto object-cover w-full')}
                  src={imageData.image}
                  alt="Post Image"
                />
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  )
}

interface IImagePreview {
  images: ImageCrop[]
  showAllImages: () => void
}

const ImagePreview: FunctionComponent<IImagePreview> = ({
  images,
  showAllImages,
}) => {
  const [showImageFullsize, setShowImageFullsize] = useState(false)
  const [selectedImage, setSelectedImage] = useState<ImageCrop>()

  const handleOpenImage = (selection: ImageCrop) => {
    setShowImageFullsize(true)
    setSelectedImage(selection)
  }

  return (
    <>
      {selectedImage && (
        <ImageModal
          imageData={selectedImage}
          isOpen={showImageFullsize}
          onClose={() => setShowImageFullsize(false)}
        />
      )}
      {images?.slice(0, 2).map((imageData: ImageCrop, index: number) => {
        return (
          <Button onClick={() => handleOpenImage(imageData)} key={index}>
            <img
              className={clsx('rounded-md max-h-auto object-cover w-[250px]')}
              src={imageData.image}
              alt="Post Image"
            />
          </Button>
        )
      })}
      {images?.length > 2 && (
        <button
          onClick={showAllImages}
          className="py-2 px-2 w-full rounded-md bg-gray-dark/90"
        >
          <h1 className="font-semibold text-white text-md">
            See more images...
          </h1>
        </button>
      )}
    </>
  )
}

export default ImagePreview
