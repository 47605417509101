import type { GetHomeFeed_my_connections_posts_liked_profiles } from 'operations/GetHomeFeed'

import { FunctionComponent } from 'react'

import { useProfile } from 'components/hooks'

import AuthorProfile from './author_profile'

interface PeopleLikedPostProps {
  people: GetHomeFeed_my_connections_posts_liked_profiles[]
  hidden?: boolean
  isAnonymousPost: boolean
  postAuthorId: number
}
const PeopleLikedPost: FunctionComponent<PeopleLikedPostProps> = ({
  people,
  hidden,
  isAnonymousPost,
  postAuthorId,
}) => {
  const isLikedFromPostAuthor = (
    like: GetHomeFeed_my_connections_posts_liked_profiles,
  ) => {
    return like.from_profile_id === postAuthorId
  }

  return (
    <div>
      {!hidden && (
        <ul role="list" className="people-liked-post">
          {people.map((p, i) => (
            <li key={i} className="flex py-1">
              <AuthorProfile
                author={p.from_profile}
                isAnonymous={isLikedFromPostAuthor(p) && isAnonymousPost}
              />
            </li>
          ))}
        </ul>
      )}
    </div>
  )
}

export default PeopleLikedPost
