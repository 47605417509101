import { UpdatePost, UpdatePostVariables } from 'operations/UpdatePost'

import { M_UPDATE_POST } from 'lib/mutations/posts'
import { Q_GET_HOME_FEED } from 'lib/queries/posts'

import { useMutation } from '@apollo/client'
import {
  DocumentAddIcon,
  PhotographIcon,
  PlusCircleIcon,
  PlusIcon,
  RefreshIcon,
} from '@heroicons/react/outline'
import { isEmpty } from 'ramda'
import { FunctionComponent } from 'react'

import PreviewImage from 'components/common/preview_images'
import { useProfile } from 'components/hooks'
import useImageUpload from 'components/hooks/useImageUpload'
import Upload, { ImageCrop } from 'components/upload'

interface IImageList {
  images: ImageCrop[]
  postId?: number
  isEditingPost: boolean
  addImage?: (image: ImageCrop) => void
  removeImage?: (imageUrl: string) => void
  withButton?: boolean
}

const ImageList: FunctionComponent<IImageList> = ({
  images,
  postId,
  isEditingPost,
  addImage,
  removeImage,
  withButton = true,
}) => {
  const { handleImageUpload, trackedFiles } = useImageUpload()
  const { profile: ownerProfile } = useProfile()
  const [updatePost] = useMutation<UpdatePost, UpdatePostVariables>(
    M_UPDATE_POST,
    {
      refetchQueries: [Q_GET_HOME_FEED],
    },
  )

  const handleAddImage = (image: ImageCrop) => {
    if (addImage) {
      addImage(image)
      return
    }
    if (!postId) return

    updatePost({
      variables: {
        postId,
        set: {
          images: [...images, { ...image }],
        },
      },
    })
  }

  const handleRemoveImage = (imageUrl: string) => {
    if (removeImage) {
      removeImage(imageUrl)
      return
    }
    if (!postId) return

    updatePost({
      variables: {
        postId,
        set: {
          images: images.filter((image) => image.image !== imageUrl),
        },
      },
    })
  }

  return (
    <>
      {!isEmpty(images) && (
        <div className="flex flex-wrap gap-5 items-center">
          {images?.map((img, idx: number) => (
            <PreviewImage
              imageURL={img.image}
              key={idx}
              onRemove={handleRemoveImage}
            />
          ))}
          {trackedFiles?.map((file, idx: number) => {
            if (file.state === 'uploaded') return null

            return (
              <RefreshIcon className="animate-spin max-w-[28px]" key={idx} />
            )
          })}
          <Upload
            buttonClass="rounded-full bg-white w-8 h-8 ml-2"
            onSave={(props) => handleAddImage?.(props)}
            prefix={`post-image/${ownerProfile?.id}`}
            multiple
            onImageUploadStatusChange={handleImageUpload}
          >
            <PlusIcon className="p-2 w-12 h-12 bg-gray-100 rounded-full stroke-black/40" />
          </Upload>
        </div>
      )}
      {withButton && isEditingPost && (
        <Upload
          buttonClass="rounded-full bg-white w-8 h-8 ml-2"
          onSave={(props) => handleAddImage?.(props)}
          prefix={`post-image/${ownerProfile?.id}`}
          multiple
          onImageUploadStatusChange={handleImageUpload}
        >
          <PhotographIcon className="w-4 h-4" />
        </Upload>
      )}
    </>
  )
}

export default ImageList
