import { useS3Upload } from 'next-s3-upload'
import { FunctionComponent, useCallback, useRef, useState } from 'react'
import Cropper from 'react-easy-crop'
import { v4 as uuidv4 } from 'uuid'

import { Button, Loading, Modal, Text } from 'components/ui'

import { PermissionWrapper } from '../common/hocs'

export interface ImageCrop {
  image: string
  crop?: Record<string, number>
}

export type TrackedFile = {
  file: File
  state: 'loading' | 'uploaded'
}

export interface IUploadProps {
  onSave: (data: ImageCrop) => void
  onImageUploadStatusChange?: (file: TrackedFile) => void
  prefix: string
  buttonClass?: string
  labelClass?: string
  multiple?: boolean
  aspect?: number
  permissions?: {
    action?: 'UploadPhoto' | 'InsertPost'
    variables?: { rol?: 'member' | 'approved' }
    behavior?: 'ShowOrHide' | 'Disable'
    tooltip?: string
  }
}

const Upload: FunctionComponent<IUploadProps> = ({
  children = undefined,
  buttonClass = 'w-full',
  labelClass = '',
  onSave,
  prefix = 'profile-image',
  multiple = false,
  aspect = 1,
  permissions = {
    action: 'UploadPhoto',
    behavior: 'ShowOrHide',
    variables: { rol: 'approved' },
  },
  onImageUploadStatusChange,
}) => {
  let [imageUrl, setImageUrl] = useState<string | string[]>()
  const inputRef = useRef<HTMLInputElement | null>(null)
  const multipleFileInputRef = useRef<HTMLInputElement | null>(null)
  let { uploadToS3, files } = useS3Upload()

  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [completedCrop, setCompletedCrop] = useState({ x: 0, y: 0 })
  const [zoom, setZoom] = useState(1)
  const [showFileInput, toggleFileInput] = useState(false)

  let handleFileChange = async (file: File | undefined) => {
    if (!file) {
      throw new Error('No file provided')
    }

    let { url } = await uploadToS3(file, {
      endpoint: { request: { headers: {}, body: { prefix } } },
    })
    setImageUrl(url)
  }

  const openFileDialog = () => {
    if (inputRef?.current) {
      inputRef.current.value = ''
      inputRef.current?.click()
    }
  }

  const openMultipleFileDialog = () => {
    if (multipleFileInputRef?.current) {
      multipleFileInputRef.current.value = ''
      multipleFileInputRef.current?.click()
    }
  }

  const handleMultipleFiles = async ({
    target,
  }: {
    target: EventTarget & HTMLInputElement
  }) => {
    if (!target.files) return

    const files = Array.from(target?.files)

    files.forEach(async (file) => {
      onImageUploadStatusChange?.({ file, state: 'loading' })
      const bitBuffer = await file.arrayBuffer()
      const renamedFile: File = new File([bitBuffer], uuidv4() + file.name, {
        type: file.type,
        lastModified: file.lastModified,
      })

      let { url } = await uploadToS3(renamedFile, {
        endpoint: { request: { headers: {}, body: { prefix } } },
      }).finally(() => {
        onImageUploadStatusChange?.({ file, state: 'uploaded' })
      })

      onSave({ image: url })
      toggleFileInput(false)
    })
  }

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCompletedCrop(croppedAreaPixels)
  }, [])

  //"http://localhost:3000/api/crop?x=30&y=40&w=300&h=300"
  const save = async () => {
    if (imageUrl) {
      await onSave({ image: imageUrl as string, crop: completedCrop })
      setImageUrl(undefined)
    }
  }

  return (
    <div>
      {multiple && (
        <>
          <input
            ref={multipleFileInputRef}
            type="file"
            multiple
            onChange={handleMultipleFiles}
            accept="image/png,image/jpg,image/jpeg"
            style={{ display: 'none' }}
          />
          <PermissionWrapper
            action={permissions?.action || 'UploadPhoto'}
            behavior={permissions?.behavior || 'Disable'}
            tooltip={permissions.tooltip}
            variables={permissions.variables}
          >
            <Button variant="label" onClick={openMultipleFileDialog}>
              {children}
            </Button>
          </PermissionWrapper>
        </>
      )}

      {!multiple && (
        <>
          <input
            ref={inputRef}
            onChange={(e) => handleFileChange(e.target?.files?.[0])}
            type="file"
            style={{ display: 'none' }}
            accept="image/png, image/jpeg"
          />
          <PermissionWrapper
            action={permissions?.action || 'UploadPhoto'}
            behavior={permissions?.behavior || 'Disable'}
            tooltip={permissions.tooltip}
            variables={permissions.variables}
          >
            <Button
              variant="label"
              onClick={openFileDialog}
              className={buttonClass}
            >
              {children}
            </Button>
          </PermissionWrapper>

          <>
            <Modal
              title={
                <Text variant="heading" size="lg">
                  Edit photo
                </Text>
              }
              isOpen={Boolean(imageUrl) || files[0]?.progress < 100}
              onClose={setImageUrl.bind(null, undefined)}
            >
              {files[0]?.progress < 100 && <Loading className="w-12 h-12" />}
              <div className="flex flex-col gap-3">
                <div className="flex relative h-80">
                  <div className="">
                    <Cropper
                      style={{
                        containerStyle: {
                          width: '100%',
                          height: '100%',
                        },
                        cropAreaStyle: {
                          width: '100%',
                          height: '100%',
                        },
                      }}
                      image={imageUrl as string}
                      crop={crop}
                      zoom={zoom}
                      zoomSpeed={0.25}
                      aspect={aspect}
                      onCropChange={setCrop}
                      onCropComplete={onCropComplete}
                      onZoomChange={setZoom}
                    />
                  </div>
                </div>
                <div className="flex self-center">
                  <input
                    type="range"
                    value={zoom}
                    min={1}
                    max={3}
                    step={0.1}
                    aria-labelledby="Zoom"
                    onChange={(e) => {
                      setZoom(parseFloat(e.target.value))
                    }}
                  />
                </div>
                <Button variant="secondary" className="w-full" onClick={save}>
                  Save
                </Button>
              </div>
            </Modal>
          </>
        </>
      )}
    </div>
  )
}

export default Upload
