import { LinkPreviewData_link_preview } from 'operations/LinkPreviewData'

import Link from 'next/link'
import { isEmpty, isNil } from 'ramda'
import { FunctionComponent, useState } from 'react'
import { twMerge } from 'tailwind-merge'

import { MentionsDetector } from 'components/common/hocs'
import LinkPreview from 'components/link_preview'
import { Text } from 'components/ui'
import { ImageCrop } from 'components/upload'

import { parsePostTitleUrl } from 'utils/posts/helper'

import ImagePreview from '../../common/image_preview'
import ImageViewer from '../../common/image_viewer'

interface IRegularPostBody {
  id: number
  title: string
  content: string
  linkPreview: LinkPreviewData_link_preview
  images: ImageCrop[]
  customPadding?: string
}

const RegularPostBody: FunctionComponent<IRegularPostBody> = ({
  id,
  title,
  content,
  linkPreview,
  images,
  customPadding,
}) => {
  const [showAllImages, setShowAllImages] = useState<boolean>(false)

  const closeAllImages = () => setShowAllImages(false)

  return (
    <div className="flex flex-col gap-2">
      <div className={twMerge('', customPadding)}>
        <Link
          href={`/posts/${id}/${parsePostTitleUrl(title)}`}
          prefetch={false}
          passHref
        >
          <Text variant="heading">{title}</Text>
        </Link>
      </div>
      <div
        className={twMerge('whitespace-pre-line post-content', customPadding)}
      >
        <MentionsDetector content={content} />
      </div>
      {!isNil(linkPreview) && (
        <div className="-mx-7">
          <LinkPreview
            linkPreview={linkPreview}
            className=""
            textBoxClassName="border-x-0"
          />
        </div>
      )}
      {!isEmpty(images) && (
        <div className="flex flex-wrap gap-2 justify-center">
          <ImagePreview
            images={images}
            showAllImages={() => setShowAllImages(true)}
          />
          <ImageViewer
            closeAllImages={closeAllImages}
            images={images}
            showAllImages={showAllImages}
          />
        </div>
      )}
    </div>
  )
}

export default RegularPostBody
