import type { ProfileFields } from 'operations/ProfileFields'

import Link from 'next/link'
import { FunctionComponent } from 'react'
import { twMerge } from 'tailwind-merge'

import { Avatar, Text } from 'components/ui'

import type {
  FeedElement,
  HomeFeedType,
  PostOptionalFields,
} from 'utils/posts/feed'
import { isNews as isNewsGuard } from 'utils/posts/feed'

interface HeaderUserCardProps {
  profile: ProfileFields
  post?: FeedElement
}

export const HeaderPersonCard: FunctionComponent<HeaderUserCardProps> = ({
  profile,
}) => {
  return (
    <div className="flex flex-wrap gap-2 items-center">
      <div>
        <Link href={`/be/${profile.username}`} passHref prefetch={false}>
          <Avatar
            profile={profile}
            layout="fill"
            className="object-cover rounded-full"
            alt="Profile image"
            size={8}
          />
        </Link>
      </div>
      <Link
        href={`/be/${profile.username}`}
        passHref
        prefetch={false}
        legacyBehavior
      >
        <Text
          weight="bold"
          variant="small"
          color="black"
          className="cursor-pointer hover:underline"
        >
          {`${profile.first_name} ${profile.last_name}`.trim()}
        </Text>
      </Link>
    </div>
  )
}

interface PostHeaderProps {
  postHeaderType: 'LIKED' | 'COMMENTED' | 'NEWS'
  post: HomeFeedType
  likedProfilesConnections?: PostOptionalFields['liked_profiles_connections']
  commentedProfilesConnections?: PostOptionalFields['commented_profiles_connections']
}

const PostHeader: FunctionComponent<PostHeaderProps> = ({
  postHeaderType,
  post,
  likedProfilesConnections,
  commentedProfilesConnections,
}) => {
  const isLiked = postHeaderType === 'LIKED'
  const isCommented = postHeaderType === 'COMMENTED'
  const isNews = postHeaderType === 'NEWS'

  return (
    <div
      className={twMerge(
        'mx-2 border-b border-gray-400',
        isNews ? 'py-3.5' : 'py-2.5',
      )}
    >
      {isLiked &&
        likedProfilesConnections &&
        likedProfilesConnections.length > 0 && (
          <div
            className="flex items-center text-center"
            style={{ flexBasis: '95%' }}
          >
            <div className="flex flex-wrap gap-2 items-center">
              {likedProfilesConnections.length === 1 ? (
                <>
                  <HeaderPersonCard
                    profile={likedProfilesConnections[0].from_profile}
                  />
                  <Text variant="small">likes this</Text>
                </>
              ) : (
                <>
                  <HeaderPersonCard
                    profile={likedProfilesConnections[0].from_profile}
                  />
                  <Text variant="small">and</Text>
                  <HeaderPersonCard
                    profile={likedProfilesConnections[1].from_profile}
                  />
                  <Text variant="small">like this</Text>
                </>
              )}
            </div>
          </div>
        )}
      {isCommented &&
        commentedProfilesConnections &&
        commentedProfilesConnections.length > 0 && (
          <div className="flex items-center text-center">
            <div className="flex flex-wrap gap-2 items-center">
              {commentedProfilesConnections.length === 1 ? (
                <>
                  <HeaderPersonCard
                    profile={commentedProfilesConnections[0].author}
                  />
                  <Text variant="small">commented on this</Text>
                </>
              ) : (
                <>
                  <HeaderPersonCard
                    profile={commentedProfilesConnections[0].author}
                  />
                  <Text variant="small">and</Text>
                  <HeaderPersonCard
                    profile={commentedProfilesConnections[1].author}
                  />
                  <Text variant="small">commented on this</Text>
                </>
              )}
            </div>
          </div>
        )}
    </div>
  )
}

export default PostHeader
