import { XIcon } from '@heroicons/react/outline'
import Image from 'next/image'
import { FunctionComponent } from 'react'

interface IPreviewImage {
  imageURL: string
  width?: number
  height?: number
  onRemove: (imageUrl: string) => void
}

const PreviewImage: FunctionComponent<IPreviewImage> = ({
  imageURL,
  width = 40,
  height = 40,
  onRemove,
}) => {
  return (
    <div className="relative w-[80px] min-h-fit">
      <button
        onClick={() => onRemove(imageURL)}
        className="absolute -top-2 -right-2 z-50 p-0.5 rounded-full bg-primary-300"
      >
        <XIcon className="w-3.5 h-3.5 stroke-primary" />
      </button>
      <div className="overflow-hidden rounded border border-light-border">
        <Image
          src={imageURL}
          width={width}
          height={height}
          layout="responsive"
          alt="preview-image"
          objectFit="cover"
        />
      </div>
    </div>
  )
}

export default PreviewImage
