import { formatDistanceToNow } from 'date-fns'
import Link from 'next/link'
import { FunctionComponent } from 'react'

import { OrganizationType } from 'components/business_profiles/business_profiles.types'
import CurrentJob from 'components/common/current_job'
import { Experience } from 'components/profile/profile.interface'
import { Text } from 'components/ui'

import { parsePostTitleUrl } from 'utils/posts/helper'

interface IUserInfo {
  releaseDate: Date
  authorUsername: string
  postId: number
  authorFullname: string
  currentJob?: string | null
  experiences?: Experience[]
  title: string
  isAnonymous: boolean
  isEditing: boolean
  businessType?: OrganizationType
}

const UserInfo: FunctionComponent<IUserInfo> = ({
  releaseDate,
  authorUsername,
  authorFullname,
  title,
  postId,
  currentJob,
  experiences,
  isAnonymous,
  isEditing,
  businessType,
}) => {
  const isBusiness = Boolean(businessType)
  const anonymousText = `Anonymous ${isBusiness ? '' : 'Member'}`
  return (
    <div>
      {isAnonymous && !isEditing ? (
        <Text variant="body" weight="semibold" color="black">
          {anonymousText}
        </Text>
      ) : (
        <div>
          <Link
            href={`${
              isBusiness ? '/page/org' : '/be'
            }/${authorUsername.trim()}`.trim()}
            passHref
            prefetch={false}
            legacyBehavior
          >
            <Text variant="body" weight="semibold" color="black">
              {authorFullname}
            </Text>
          </Link>
          <CurrentJob currentJob={currentJob} experiences={experiences} />
          {businessType && (
            <Text variant="small" capitalize>
              {businessType}
            </Text>
          )}
        </div>
      )}
      <Link
        href={`/posts/${postId}/${parsePostTitleUrl(title)}`}
        passHref
        prefetch={false}
        legacyBehavior
      >
        <Text variant="small" className="cursor-pointer hover:underline">
          {formatDistanceToNow(new Date(releaseDate), {
            addSuffix: true,
          })}
        </Text>
      </Link>
    </div>
  )
}

export default UserInfo
