import CommentIcon from '@/components/common/comment_icon'
import { PermissionWrapper } from '@/components/common/hocs'
import LikeIcon from '@/components/common/like_icon'
import { useSubmitComment } from '@/components/hooks'
import { Button, PostButton, Text } from '@/components/ui'
import type {
  GetHomeFeed_business_posts_liked_profiles,
  GetHomeFeed_my_connections_posts_liked_profiles,
} from '@/operations/GetHomeFeed'
import type { GetPostComments_post_comments } from '@/operations/GetPostComments'
import { ReplyIcon } from '@heroicons/react/outline'
import { isEmpty } from 'ramda'
import { useState } from 'react'

import CommentList from '../../common/comment/comment_list'
import PeopleLikedPost from '../../common/people_liked_post'
import PostReactions from '../../common/post_reactions'
import ReplyingBox from '../../common/replying_box'

type NewsPostActionsType = {
  onLike: () => void
  onShare: () => void
  likes: number
  isLiked: boolean
  likedProfiles?:
    | GetHomeFeed_my_connections_posts_liked_profiles[]
    | GetHomeFeed_business_posts_liked_profiles[]
  newsPostId?: number
  comments?: GetPostComments_post_comments[]
  disabledLikeButton?: boolean
}

const NewsPostActions = ({
  onLike,
  likes,
  onShare,
  isLiked,
  likedProfiles,
  disabledLikeButton,
  newsPostId,
  comments,
}: NewsPostActionsType) => {
  const [whoLikedHidden, setWhoLikedHidden] = useState(true)
  const { onPost, closeReply, goToMainPost } = useSubmitComment({
    postType: 'post',
  })

  return (
    <div>
      <div className="flex flex-row-reverse justify-between items-center mb-5">
        <div className="flex gap-2">
          <PermissionWrapper behavior="Disable" action="LikePost">
            <PostButton
              onClick={onLike}
              disabled={disabledLikeButton}
              icon={
                <LikeIcon className="w-5 h-5 md:w-4 md:h-4" liked={isLiked} />
              }
              label="Like"
            />
          </PermissionWrapper>
          <PermissionWrapper behavior="Disable" action="CommentPost">
            <PostButton
              onClick={() => goToMainPost({ postId: newsPostId! })}
              label="Reply"
              icon={
                <CommentIcon className="w-5 h-5 md:w-4 md:h-4 stroke-primary" />
              }
            />
          </PermissionWrapper>
          <PermissionWrapper behavior="Disable" action="SharePost">
            <PostButton
              onClick={onShare}
              icon={
                <ReplyIcon className="w-5 h-5 md:w-4 md:h-4 -scale-x-[1] stroke-primary" />
              }
              label="Share"
            />
          </PermissionWrapper>
        </div>
        <PostReactions
          likes={likes}
          commentsCount={comments?.length!}
          updateWhoLikedHidden={() => setWhoLikedHidden(!whoLikedHidden)}
        />
      </div>

      {!whoLikedHidden && (
        <PeopleLikedPost
          people={likedProfiles ?? []}
          hidden={whoLikedHidden}
          isAnonymousPost={false}
          postAuthorId={-1}
        />
      )}
      <ReplyingBox
        isAnonymous={false}
        category="post"
        isReply={false}
        toPostId={newsPostId!}
        postAuthorId={-1}
        closeReplyingBox={closeReply}
        onPost={onPost}
      />
      {!isEmpty(comments) && (
        <CommentList
          category="post"
          toPostId={newsPostId!}
          isAnonymousPost={false}
          comments={comments!}
          postAuthorId={-1}
        />
      )}
    </div>
  )
}

export default NewsPostActions
