import type { GetReferrals_opportunities } from 'operations/GetReferrals'

import {
  ChatIcon,
  DesktopComputerIcon,
  EyeIcon,
  LocationMarkerIcon,
  ReplyIcon,
  UserIcon,
} from '@heroicons/react/outline'
import { ExclamationCircleIcon } from '@heroicons/react/solid'
import { formatDistanceToNow } from 'date-fns'
import Link from 'next/link'
import { isEmpty, isNil } from 'ramda'
import { Dispatch, FunctionComponent, SetStateAction } from 'react'
import { twMerge } from 'tailwind-merge'
import { parseReferralTitle, profilesLink } from 'utils'

import BadgeCheckIcon from 'components/common/badge_check_icon'
import CurrentJob from 'components/common/current_job'
import { MentionsDetector } from 'components/common/hocs'
import { TextIcon } from 'components/opportunities/new-referrals/referral'
import { Modalities } from 'components/profile/user_basic_details/modality_badge'
import { Box, Text } from 'components/ui'

import PostAvatar from '../../common/post_avatar'

interface ReferralProps {
  post: GetReferrals_opportunities
  setIsEditingReferral?: Dispatch<SetStateAction<boolean>>
  setEditingReferralData?: Dispatch<
    SetStateAction<GetReferrals_opportunities | undefined>
  >
}

const ReferralPost: FunctionComponent<ReferralProps> = ({ post: referral }) => {
  const {
    author,
    business,
    filled: isFilled,
    title,
    content,
    comments,
    respondants,
    subcategory,
    opportunity_respondants_outsiders: outsiderRespondants,
    seeking_info: { sessionModality: modality, locations, ageGroups },
  } = referral

  const fullName = author
    ? `${author?.first_name} ${author?.last_name}`.trim()
    : business?.name

  const numberOfRespondants = respondants.length + outsiderRespondants.length

  const isUrgent = Boolean(referral?.urgent) || false

  const isLookingForClients = subcategory === 'SEEKING_CLIENTS'

  const referralLink = `/opportunities/referrals/${
    referral?.uuid
  }/${parseReferralTitle(title)}`

  const iconStyle = 'w-6 h-6 stroke-gray-700'

  return (
    <Box
      adaptToMobile
      className="p-7 border-2 border-b-0 border-primary"
      footer={
        <Link href={referralLink} passHref prefetch={false} legacyBehavior>
          <div className="flex gap-2 justify-center items-center p-3 w-full cursor-pointer bg-primary hover:bg-primary-hover">
            <EyeIcon className="w-6 h-6 stroke-white" />
            <Text variant="subheading" color="white" className="text-center">
              View Full Referral
            </Text>
          </div>
        </Link>
      }
      footerClassName="p-0 border-2 border-primary boder-t-0 hover:border-primary-hover"
    >
      <div className="flex flex-col gap-4 w-full">
        <div className="flex flex-wrap justify-between items-center w-full">
          <Link
            href={profilesLink(!author ? (business as any) : (author as any))}
            passHref
            prefetch={false}
            legacyBehavior
          >
            <div className="flex flex-wrap gap-2 items-center">
              {author ? (
                <PostAvatar author={author} />
              ) : (
                <PostAvatar author={business!} />
              )}
              <div className="cursor-pointer">
                <Text variant="body" weight="semibold" color="black">
                  {fullName}
                </Text>
                <CurrentJob
                  currentJob={author?.current_job_new}
                  experiences={author?.experiences}
                />
                <Link
                  href={referralLink}
                  passHref
                  prefetch={false}
                  legacyBehavior
                >
                  <Text
                    variant="small"
                    className="cursor-pointer hover:underline"
                  >
                    {formatDistanceToNow(new Date(referral.created_at), {
                      addSuffix: true,
                    })}
                  </Text>
                </Link>
              </div>
            </div>
          </Link>
          <div className="flex flex-col gap-4">
            <div className="flex gap-4 items-center h-fit">
              <Text
                variant="extra-small"
                weight="bold"
                color="fourth"
                className="hidden md:flex"
              >
                REFERRAL
              </Text>
              <div
                className={twMerge(
                  'flex justify-center items-center w-6 h-6 rounded-full border-2 border-gray-400 bg-fourth',
                )}
              >
                <Text
                  className="text-white"
                  variant="extra-small"
                  weight="bold"
                >
                  R
                </Text>
              </div>
            </div>
            {isFilled && (
              <div className="flex flex-row-reverse gap-4 items-center w-full">
                <BadgeCheckIcon className="w-7 h-7 fill-blue-badge-check" />
                <Text
                  variant="extra-small"
                  weight="bold"
                  color="black"
                  uppercase
                  className="text-end"
                >
                  Filled
                </Text>
              </div>
            )}
          </div>
        </div>
        <div className="flex flex-col gap-3">
          {isUrgent && (
            <div className="flex gap-2 items-center">
              <ExclamationCircleIcon className="w-6 h-6 fill-error" />
              <Text className="text-error" weight="light" size="sm">
                Marked Urgent
              </Text>
            </div>
          )}
          <Link href={referralLink} passHref prefetch={false} legacyBehavior>
            <Text variant="heading" className="cursor-pointer">
              {title}
            </Text>
          </Link>
          {content && <MentionsDetector content={content} />}
        </div>
        <div className="flex justify-between">
          <div className="flex gap-5">
            {locations?.length > 0 && (
              <TextIcon
                icon={<LocationMarkerIcon className={iconStyle} />}
                className="capitalize"
              >
                {isEmpty(locations[0].city)
                  ? locations[0].state
                  : locations[0].city}
                {locations.length > 1 && (
                  <sup className="ml-1 text-gray-700">
                    +{locations.length - 1}
                  </sup>
                )}
              </TextIcon>
            )}
            {modality && (
              <TextIcon icon={<DesktopComputerIcon className={iconStyle} />}>
                {modality === Modalities.online && 'Online'}
                {modality === Modalities.inperson && 'In-Person'}
                {modality === Modalities.both && 'Online & In-Person'}
              </TextIcon>
            )}
            {!isEmpty(ageGroups) && !isNil(ageGroups) && (
              <TextIcon icon={<UserIcon className={iconStyle} />}>
                {ageGroups[0]}{' '}
                {ageGroups.length > 1 && `and ${ageGroups.length - 1}+`}
              </TextIcon>
            )}
          </div>
          <div className="hidden gap-5 md:flex">
            <TextIcon icon={<ChatIcon className={iconStyle} />}>
              {comments.length}
            </TextIcon>
            {!isLookingForClients && (
              <TextIcon icon={<ReplyIcon className={iconStyle} />}>
                {numberOfRespondants}
              </TextIcon>
            )}
          </div>
        </div>
      </div>
    </Box>
  )
}

export default ReferralPost
