import { FunctionComponent } from 'react'

import { PermissionWrapper } from 'components/common/hocs'
import { Text } from 'components/ui'

interface IPostReactions {
  likes: number
  commentsCount: number
  shareCounter?: number
  updateWhoLikedHidden?: () => void
  updateHiddenComments?: () => void
}

const PostReactions: FunctionComponent<IPostReactions> = ({
  likes,
  commentsCount,
  shareCounter,
  updateWhoLikedHidden,
  updateHiddenComments,
}) => {
  return (
    <div className="flex items-center divide-x">
      {likes > 0 && (
        <PermissionWrapper action="LikePost" behavior="Disable">
          <Text
            onClick={updateWhoLikedHidden}
            variant="extra-small"
            className="px-2 cursor-pointer"
            color="primary"
          >
            {likes.toLocaleString('en-US')} {likes === 1 ? 'like' : 'likes'}
          </Text>
        </PermissionWrapper>
      )}
      {commentsCount > 0 && (
        <PermissionWrapper action="CommentPost" behavior="Disable">
          <Text
            onClick={updateHiddenComments}
            variant="extra-small"
            className="px-2"
            color="primary"
          >
            {commentsCount.toLocaleString('en-US')}{' '}
            {commentsCount === 1 ? 'comment' : 'comments'}
          </Text>
        </PermissionWrapper>
      )}
      {shareCounter !== undefined && shareCounter > 0 && (
        <PermissionWrapper action="SharePost" behavior="Disable">
          <Text variant="extra-small" className="px-2" color="primary">
            {shareCounter.toLocaleString('en-US')}{' '}
            {shareCounter === 1 ? 'share' : 'shares'}
          </Text>
        </PermissionWrapper>
      )}
    </div>
  )
}

export default PostReactions
