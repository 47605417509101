/* eslint-disable @next/next/no-img-element */
import { GetMyNews_news } from '@/operations/GetMyNews'
import type { GetPostComments_post_comments } from '@/operations/GetPostComments'
import { BusinessPosts, ConnectionsPosts } from '@/utils/posts/feed'
import { LinkIcon } from '@heroicons/react/outline'
import { formatDistanceToNow } from 'date-fns'
import { isNil } from 'ramda'
import { useRef } from 'react'
import { twMerge } from 'tailwind-merge'

import { useFollowNews } from 'components/hooks'
import { Source } from 'components/hooks/useFollowNews'
import { Avatar, Box, Menu, Text } from 'components/ui'

import { categoryColors } from '../../post'
import NewsPostActions from './news_actions'

interface INewsPost {
  post: ConnectionsPosts | BusinessPosts | GetMyNews_news
  likes?: number
  isLiked?: boolean
  variant?: 'vertical' | 'horizontal' | 'small'
  onLike?: () => void
  onShare?: () => void
  comments?: GetPostComments_post_comments[]
  disabledLikeButton?: boolean
}

const NewsPost = ({
  post,
  likes,
  isLiked,
  variant = 'vertical',
  comments,
  disabledLikeButton,
  onLike,
  onShare,
}: INewsPost) => {
  const { onFollow } = useFollowNews()
  const newsImageRef = useRef<HTMLImageElement | null>(null)

  const { id: newsPostId } = post ?? {}
  const isNewsPost = post.__typename == 'posts'
  const news = isNewsPost ? post?.news : post
  const likedProfiles = isNewsPost ? post?.liked_profiles : ''
  const {
    url,
    author,
    description,
    image,
    news_source: source,
    title,
    icon,
    publication_date: date,
  } = news ?? {}

  enum newsCategory {
    NEWS = 'NEWS',
    BLOG = 'BLOG',
  }
  const category =
    post.__typename == 'news' ? newsCategory.NEWS : post?.post_category?.type
  const avatar = icon ? icon.image : undefined

  const isVertical = variant === 'vertical'
  const isHorizontal = variant === 'horizontal'
  const isSmall = variant === 'small'

  // Check if image is available and if it is from Google
  const shouldDisplayImage = (image: any) => {
    return !isNil(image) && image.includes('https')
  }

  const handleImageError = () => {
    if (newsImageRef === null || newsImageRef?.current === null) {
      return null
    }

    newsImageRef.current.style.display = 'none'
  }

  return (
    <Box
      className={twMerge(
        'h-full p-0',
        isVertical && 'flex-col',
        (isHorizontal || isSmall) && 'flex-col md:flex-row-reverse',
      )}
      adaptToMobile
    >
      {shouldDisplayImage(image) && (
        <img
          ref={newsImageRef}
          className={twMerge(
            'object-cover flex-shrink-0',
            isVertical && 'w-full h-80',
            isHorizontal && 'w-full md:w-1/2',
            isSmall && 'w-full md:w-2/5',
          )}
          onError={handleImageError}
          src={image as string | undefined}
          alt={title}
        />
      )}
      <div className="flex flex-col flex-1 gap-5 p-5">
        <div className="flex gap-5 justify-between">
          <div className="flex gap-4 items-start">
            <Avatar size={12} avatar={{ image: avatar }} fill />
            <div className="flex flex-col gap-1">
              <Text variant="heading" className="line-clamp-1">
                {author}
              </Text>
              <Text variant="small">
                {source?.name} -{' '}
                {formatDistanceToNow(new Date(date), {
                  addSuffix: true,
                })}
              </Text>
            </div>
          </div>
          <div className="flex flex-col flex-none gap-1 items-end">
            {false && (
              <Menu
                options={[
                  {
                    label: 'Turn on notifications for this publisher',
                    onClick: () => {},
                  },
                  {
                    label: 'Unsubscribe from this publisher',
                    onClick: () => onFollow(source as Source),
                  },
                ]}
                className="mr-0.5"
              />
            )}
            {isVertical && (
              <div className="flex gap-4 justify-end items-center uppercase h-fit">
                <Text
                  variant="extra-small"
                  weight="bold"
                  className={twMerge(
                    'hidden md:flex',
                    `text-${categoryColors['NEWS']}`,
                  )}
                  uppercase
                >
                  {category == newsCategory.NEWS
                    ? 'News Story'
                    : 'Belongly Blog'}
                </Text>
                <div
                  className={twMerge(
                    'flex justify-center items-center w-6 h-6 rounded-full border-2 border-gray-400',
                    `bg-${categoryColors['NEWS']}`,
                  )}
                >
                  <Text
                    className="text-white"
                    variant="extra-small"
                    weight="bold"
                  >
                    {category == newsCategory.NEWS ? 'N' : 'B'}
                  </Text>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="flex flex-col gap-5">
          <a href={url} target="_blank" rel="noopener noreferrer">
            <Text
              variant="heading"
              className={twMerge(
                'line-clamp-2',
                isVertical && 'line-clamp-none',
              )}
            >
              {title}
            </Text>
          </a>
          {!isSmall && (
            <Text
              className={twMerge(
                'overflow-hidden text-ellipsis line-clamp-2',
                isVertical && 'line-clamp-none',
              )}
              variant="body"
            >
              {description}
            </Text>
          )}
        </div>
        <a
          className="flex gap-1 items-center group"
          href={url}
          target="_blank"
          rel="noopener noreferrer"
        >
          <LinkIcon className="w-3 h-3 stroke-text-small" />
          <Text variant="small" className="group-hover:underline">
            {author}
          </Text>
        </a>
        {likedProfiles && (
          <NewsPostActions
            onLike={onLike!}
            disabledLikeButton={disabledLikeButton}
            onShare={onShare!}
            likes={likes!}
            isLiked={isLiked!}
            newsPostId={newsPostId}
            likedProfiles={likedProfiles}
            comments={comments}
          />
        )}
      </div>
    </Box>
  )
}

export default NewsPost
