import { GetNameById } from 'operations/GetNameById'
import { UpdatePost, UpdatePostVariables } from 'operations/UpdatePost'

import { M_UPDATE_POST } from 'lib/mutations/posts'
import { Q_GET_NAME_BY_ID } from 'lib/queries/profiles'

import { useLazyQuery, useMutation } from '@apollo/client'
import * as linkify from 'linkifyjs'
import { isNil } from 'ramda'
import { useEffect, useMemo, useState } from 'react'
import {
  capitalizeWord,
  getId,
  isMention,
  isTag,
  replaceMentions,
  whether,
} from 'utils'

import { MentionsInput } from 'components/common/hocs'
import useLinkPreview from 'components/hooks/usePreviewLink'
import LinkPreview from 'components/link_preview'
import { Button, Modal, TextInput } from 'components/ui'

import {
  HomeFeedType,
  isPost,
  isRegularPost,
  updatePostFromCache,
} from 'utils/posts/feed'

import ImageList from './image_list'
import ProfileSection from './profile_section'

const EditPost = ({
  isEditing,
  closeDialog,
  post,
}: {
  isEditing: boolean
  closeDialog: () => void
  post: HomeFeedType
}) => {
  const [postContent, setPostContent] = useState<string>(
    isRegularPost(post) ? post.content ?? '' : '',
  )
  const [postTitle, setPostTitle] = useState(
    isRegularPost(post) ? post.title : '',
  )

  /**
   * This useEffect is in charge of resetting the post data into the edit post component
   * DONT REMOVE: in case there is a new post added recently which makes the post list update
   * then it won't catch the most recent post data but will show last one before that when
   * trying to edit
   * in case there is a new post added recently which makes the post list update
   * if its erased, then it won't catch the most recent post data but will show
   * the last one before that when trying to edit
   */
  useEffect(() => {
    setPostContent(isRegularPost(post) ? post.content ?? '' : '')
    setPostTitle(isRegularPost(post) ? post.title : '')
  }, [isEditing, post])

  const [getProfileName] = useLazyQuery<GetNameById>(Q_GET_NAME_BY_ID)

  const linkPreviewUrl = useMemo(() => {
    const results = linkify.find(postContent)
    return whether(!isNil(results) && results.length > 0, results[0]?.href)
  }, [postContent])

  const linkPreview = useLinkPreview(linkPreviewUrl)

  const handleExistingMentions = () => {
    /** We are doing this way cause when we have mentions comming from the database
     * the second time this function is executed it will separate the profile name
     * in @[John Doe](1), so what we are doing is getting that pattern and then
     * if there are not a mentions we can proceed with the normal split, but if there
     * are we separate the words behind and after the mention and the we split it.
     */
    const wordsWithMentions = postContent.split(
      /(@\[[a-za-z]+\s[a-za-z]+\]\([0-9]\))/g,
    )

    const words: string[] = []
    if (wordsWithMentions.length > 1 && !isMention(wordsWithMentions[0])) {
      wordsWithMentions.map((word) => {
        if (isMention(word)) {
          words.push(word)
        }
        word.split(/(?=\s)|(?=^)/gm).map((w) => words.push(w))
      })
    } else {
      postContent.split(/(?=\s)|(?=^)/gm).map((w) => words.push(w))
    }

    const newWords = words.map(async (word) => {
      if (isTag(word)) {
        const { data } = await getProfileName({
          variables: {
            profileId: getId(word),
          },
        })
        const firstName = data?.profiles?.[0]?.first_name
        const lastName = data?.profiles?.[0]?.last_name

        const fullName = `${capitalizeWord(firstName ?? '')} ${capitalizeWord(
          lastName ?? '',
        )}`

        return ` @[${fullName}](${getId(word)})`
      }

      return word
    })

    return newWords
  }

  const [updatePost] = useMutation<UpdatePost, UpdatePostVariables>(
    M_UPDATE_POST,
    {
      onCompleted: (post) => {
        if (!post.update_posts) return
        updatePostFromCache(post.update_posts.returning[0] as HomeFeedType)
      },
    },
  )

  useEffect(() => {
    Promise.all(handleExistingMentions()).then((results) => {
      results.forEach((result, index) => {
        setPostContent((prevValue) => {
          if (index == 0) {
            return result
          }

          return prevValue + result
        })
      })
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!isPost(post)) {
    return null
  }

  const author = post.author
  const business = post.business
  const authorFullname = author
    ? `${author.first_name} ${author.last_name}`
    : business
    ? `${business?.name}`
    : ''

  const handleChangeContent = (text: string) => {
    setPostContent(text)
  }

  const handleChangeTitle = (text: string) => {
    setPostTitle(text)
  }

  const handleSave = () => {
    if (!isEditing) return

    updatePost({
      variables: {
        postId: post.id,
        set: {
          title: postTitle,
          content: replaceMentions(postContent),
          link_preview: linkPreview,
        },
      },
    })

    closeDialog()
  }

  return (
    <Modal onClose={closeDialog} isOpen={isEditing} title="Edit Post">
      <ProfileSection
        isAnonymous={post.is_anonymous ?? false}
        isEditing={isEditing}
        author={author ?? business}
        authorFullname={authorFullname}
        postId={post.id}
        postTitle={post.title}
        releaseDate={post.release_date}
      />
      <TextInput
        value={postTitle}
        onChange={(e) => handleChangeTitle(e.target.value)}
        className="font-bold border-none"
      />
      <div>
        <MentionsInput
          hasBorder={false}
          content={postContent}
          onChange={handleChangeContent}
          placeholder="Post content"
        />
        <LinkPreview linkPreview={linkPreview} />
      </div>

      <div className="flex justify-end items-end py-5 mt-10">
        <div className="w-full">
          <ImageList images={post.images} postId={post.id} isEditingPost />
        </div>
        <Button className="max-h-10" variant="third" onClick={handleSave}>
          Save
        </Button>
      </div>
    </Modal>
  )
}

export default EditPost
