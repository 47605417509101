/* eslint-disable @next/next/no-img-element */
import { DeletePost, DeletePostVariables } from 'operations/DeletePost'
import { GetPostComments_post_comments } from 'operations/GetPostComments'
import { GetShareCounter } from 'operations/GetShareCounter'

import { M_DELETE_POST } from 'lib/mutations/posts'
import { Q_GET_POST_SHARE_COUNTER } from 'lib/queries/posts'

import { useMutation, useQuery } from '@apollo/client'
import { PencilIcon, TrashIcon } from '@heroicons/react/outline'
import { PaperAirplaneIcon } from '@heroicons/react/solid'
import { isEmpty } from 'ramda'
import { FunctionComponent, useState } from 'react'
import { twMerge } from 'tailwind-merge'

import CommentIcon from 'components/common/comment_icon'
import { PermissionWrapper } from 'components/common/hocs'
import LikeIcon from 'components/common/like_icon'
import { useProfile } from 'components/hooks'
import useSubmitComment from 'components/hooks/useSubmitComment'
import { Box, ConfirmationModal, Menu, PostButton, Text } from 'components/ui'

import {
  BusinessPosts,
  ConnectionsPosts,
  postsVar,
  removePostFromCache,
} from 'utils/posts/feed'

import CommentList from '../../common/comment/comment_list'
import EditPost from '../../common/edit_post'
import PeopleLikedPost from '../../common/people_liked_post'
import PostHeader from '../../common/post_header'
import PostReactions from '../../common/post_reactions'
import ProfileSection from '../../common/profile_section'
import ReplyingBox from '../../common/replying_box'
import RegularPostBody from './regular_post_body'

interface RegularPostProps {
  post: ConnectionsPosts | BusinessPosts
  likes?: number
  showAllComments?: boolean
  type?: string
  optionalHeader?: JSX.Element
  hasCardContentClass?: boolean
  isOnFeed?: boolean
  isLiked?: boolean
  comments?: GetPostComments_post_comments[]
  disabledLikeButton?: boolean
  onLike: () => void
  onShare: () => void
}

export const categoryColors = {
  NEWS: 'gray',
  DISCUSSION: 'primary',
  REFERRAL: 'link',
}

const RegularPost = ({
  post,
  likes = 0,
  optionalHeader,
  isOnFeed = false,
  isLiked,
  comments,
  disabledLikeButton,
  onLike,
  onShare,
}: RegularPostProps) => {
  const { profile: ownerProfile } = useProfile()
  const [whoLikedHidden, setWhoLikedHidden] = useState(true)
  const [hiddenComments, setHiddenComments] = useState(false)
  const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] =
    useState(false)
  const [isEditingPost, setShowEditingPost] = useState(false)

  const { data: postSharesData } = useQuery<GetShareCounter>(
    Q_GET_POST_SHARE_COUNTER,
    {
      variables: { postId: post.id },
      skip: !post.id,
    },
  )

  const [deletePost] = useMutation<DeletePost, DeletePostVariables>(
    M_DELETE_POST,
    {
      onCompleted: (data) => {
        if (!data.update_posts) return
        const postId = data.update_posts.returning[0].id
        const postIndex = postsVar().findIndex((post) => post.id === postId)

        if (postIndex !== -1) {
          removePostFromCache(postIndex, postId)
          setShowDeleteConfirmationModal(false)
        }
      },
    },
  )

  const { onPost, closeReply, goToMainPost } = useSubmitComment({
    postType: 'post',
  })

  const author = post?.author
  const business = post?.business
  const shareCounter =
    postSharesData?.post_shares_aggregate?.aggregate?.count ?? 0
  const isAnonymousPost = post?.is_anonymous
  const hasEditPermissions =
    ownerProfile?.id === author?.id ||
    business?.administrators.some(
      (admin) => admin.profile_id === ownerProfile?.id,
    )

  const likedProfilesConnection = post['liked_profiles_connections']
    ? post?.liked_profiles_connections?.filter((profile) => {
        if (post.is_anonymous) {
          return profile.from_profile_id !== post.author_profile_id
        }
        return true
      })
    : undefined

  const commentedProfilesConnection = post['commented_profiles_connections']
    ? post?.commented_profiles_connections?.filter(
        (profile) => !profile.is_anonymous,
      )
    : undefined

  const closeEditingModal = () => setShowEditingPost(false)

  const handleDeletePost = (postId: number) => {
    deletePost({ variables: { postId } })
  }

  const updateWhoLikedHidden = () => {
    setWhoLikedHidden(!whoLikedHidden)
    setHiddenComments(true)
  }

  const updateHiddenComments = () => {
    setHiddenComments(!hiddenComments)
    setWhoLikedHidden(true)
  }

  const commentsCount = comments?.length || 0

  const postCategory = post?.post_category?.description

  const authorFullname = author
    ? `${author.first_name} ${author.last_name}`
    : business
    ? `${business?.name}`
    : ''

  const showHeader =
    likedProfilesConnection ||
    commentedProfilesConnection ||
    (post.post_category.type === 'NEWS' &&
      !likedProfilesConnection &&
      !commentedProfilesConnection)

  return (
    <>
      <Box
        adaptToMobile
        className={twMerge(
          'flex flex-col gap-4 p-7',
          (showHeader || optionalHeader) && 'pt-0',
        )}
      >
        {(showHeader || optionalHeader) && (
          <div>
            {optionalHeader ? (
              optionalHeader
            ) : (
              <>
                {likedProfilesConnection && (
                  <PostHeader
                    postHeaderType="LIKED"
                    post={post}
                    commentedProfilesConnections={commentedProfilesConnection}
                    likedProfilesConnections={likedProfilesConnection}
                  />
                )}
                {commentedProfilesConnection && (
                  <PostHeader
                    postHeaderType="COMMENTED"
                    post={post}
                    commentedProfilesConnections={commentedProfilesConnection}
                    likedProfilesConnections={likedProfilesConnection}
                  />
                )}
              </>
            )}
          </div>
        )}
        <div className="flex flex-row-reverse justify-between">
          <div className="flex flex-col gap-1 items-end">
            {hasEditPermissions && (
              <Menu
                className="self-end"
                options={[
                  {
                    label: 'Edit',
                    onClick: () => setShowEditingPost(true),
                    icon: (active) => (
                      <PencilIcon
                        className={twMerge(active && 'stroke-white', 'h-4 w-4')}
                      />
                    ),
                  },
                  {
                    label: 'Delete',
                    isDestructive: true,
                    onClick: () => setShowDeleteConfirmationModal(true),
                    icon: (active) => (
                      <TrashIcon
                        className={twMerge(active && 'stroke-white', 'h-4 w-4')}
                      />
                    ),
                  },
                ]}
              />
            )}
            <div className="flex gap-4 justify-end items-center uppercase h-fit">
              <Text
                variant="extra-small"
                weight="bold"
                className={twMerge(
                  'hidden md:flex',
                  `text-${
                    categoryColors[postCategory as keyof typeof categoryColors]
                  }`,
                )}
              >
                {postCategory}
              </Text>
              <div
                className={twMerge(
                  'flex justify-center items-center w-6 h-6 rounded-full border-2 border-gray-400',
                  `bg-${
                    categoryColors[postCategory as keyof typeof categoryColors]
                  }`,
                )}
              >
                <Text
                  className="text-white"
                  variant="extra-small"
                  weight="bold"
                >
                  {postCategory[0]}
                </Text>
              </div>
            </div>
          </div>
          {(author || business) && (
            <ProfileSection
              isAnonymous={post.is_anonymous ?? false}
              author={author ?? business}
              authorFullname={authorFullname}
              releaseDate={post.release_date}
              postId={post.id}
              postTitle={post.title}
              currentJob={post.author?.current_job_new}
              experiences={post.author?.experiences}
            />
          )}
        </div>

        <div className="flex flex-col gap-4">
          <RegularPostBody
            content={post.content}
            linkPreview={post.link_preview}
            id={post.id}
            images={post.images}
            title={post.title}
          />
          <div className="flex flex-col justify-between">
            <div className="flex justify-between items-center mb-5 text-right make text-primary">
              <PostReactions
                likes={likes}
                commentsCount={commentsCount}
                shareCounter={shareCounter}
                updateHiddenComments={updateHiddenComments}
                updateWhoLikedHidden={updateWhoLikedHidden}
              />
              {/* <div className="px-3">Share</div> */}
              <div className="flex gap-2 text-black">
                <PermissionWrapper action="LikePost" behavior="Disable">
                  <PostButton
                    disabled={disabledLikeButton}
                    onClick={onLike}
                    icon={
                      <LikeIcon
                        className="w-5 h-5 md:w-4 md:h-4"
                        liked={isLiked}
                      />
                    }
                    label="Like"
                  />
                </PermissionWrapper>
                <PermissionWrapper action="CommentPost" behavior="Disable">
                  <PostButton
                    onClick={
                      isOnFeed
                        ? () => goToMainPost({ postId: post.id })
                        : updateHiddenComments
                    }
                    label="Reply"
                    icon={
                      <CommentIcon className="w-5 h-5 md:w-4 md:h-4 stroke-primary" />
                    }
                  />
                </PermissionWrapper>
                <PermissionWrapper action="SharePost" behavior="Disable">
                  <PostButton
                    onClick={onShare}
                    icon={
                      <PaperAirplaneIcon className="w-5 h-5 rotate-45 md:w-4 md:h-4 fill-primary" />
                    }
                    label="Share"
                  />
                </PermissionWrapper>
              </div>
            </div>
            {!whoLikedHidden && (
              <PeopleLikedPost
                people={post.liked_profiles}
                hidden={whoLikedHidden}
                isAnonymousPost={isAnonymousPost ?? false}
                postAuthorId={post.author_profile_id ?? business?.id ?? -1}
              />
            )}
            <ReplyingBox
              isAnonymous={isAnonymousPost ?? true}
              category="post"
              isReply={false}
              toPostId={post.id}
              postAuthorId={post?.author?.id ?? business?.id ?? -1}
              postAuthor={post.author}
              closeReplyingBox={closeReply}
              onPost={onPost}
            />
            {comments && !isEmpty(comments) && (
              <CommentList
                category="post"
                toPostId={post.id}
                isAnonymousPost={isAnonymousPost ?? false}
                comments={comments}
                postAuthorId={post?.author?.id ?? business?.id ?? -1}
              />
            )}
          </div>
        </div>
      </Box>
      <EditPost
        isEditing={isEditingPost}
        closeDialog={closeEditingModal}
        post={post}
      />
      <ConfirmationModal
        message="Are you sure you want to delete this post?"
        isOpen={showDeleteConfirmationModal}
        onClose={() => setShowDeleteConfirmationModal(false)}
        onConfirm={() => handleDeletePost(post.id)}
      />
    </>
  )
}

export default RegularPost
