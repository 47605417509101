import { GetPostComments } from 'operations/GetPostComments'
import { GetProfileByOwnerId_profiles } from 'operations/GetProfileByOwnerId'
import { GetShareCounter } from 'operations/GetShareCounter'
import { PostLike, PostLikeVariables } from 'operations/PostLike'
import { PostUnLike, PostUnLikeVariables } from 'operations/PostUnLike'

import { M_POST_LIKE, M_POST_UNLIKE } from 'lib/mutations/posts'
import { Q_GET_POST_COMMENTS } from 'lib/queries/posts'

import { useMutation, useQuery } from '@apollo/client'
import { useRouter } from 'next/router'
import { FunctionComponent, useState } from 'react'
import { whether } from 'utils'

import {
  BusinessPosts,
  ConnectionsPosts,
  HomeFeedType,
  LikedPosts,
  Opportunities,
  isPost,
  parseLikedPost,
  updatePostFromCache,
} from 'utils/posts/feed'

import NewsPost from './type/news/news_post'
import ReferralPost from './type/referral/referral_post'
import RegularPost from './type/regular/regular_post'

interface PostProps {
  post: HomeFeedType
  likedPost?: boolean
  reader: Pick<GetProfileByOwnerId_profiles, 'id' | 'avatar'>
  showAllComments?: boolean
  type?: string
  optionalHeader?: JSX.Element
  hasCardContentClass?: boolean
  isOnFeed?: boolean
}

export const categoryColors = {
  NEWS: 'gray',
  DISCUSSION: 'primary',
  REFERRAL: 'link',
}

export const Post: FunctionComponent<PostProps> = ({
  post,
  likedPost,
  reader,
}) => {
  const router = useRouter()

  const [isLiked, setIsLiked] = useState(likedPost)

  const [likes, setLikes] = useState<number>(
    isPost(post) ? post?.liked_profiles_aggregate.aggregate?.count || 0 : 0,
  )

  const { data: postCommentsData } = useQuery<GetPostComments>(
    Q_GET_POST_COMMENTS,
    {
      variables: {
        postId: post?.id,
      },
    },
  )

  const [likePostMutation, { loading: likeMutationLoading }] = useMutation<
    PostLike,
    PostLikeVariables
  >(M_POST_LIKE, {
    onCompleted: (data) => {
      if (!data.insert_liked_posts_one) return
      const likedPost = data.insert_liked_posts_one as LikedPosts
      const { liked_profiles_connections, ...newPost } =
        parseLikedPost(likedPost)

      updatePostFromCache(newPost)
    },
  })
  const [unlikePostMutation, { loading: unlikeMutationLoading }] = useMutation<
    PostUnLike,
    PostUnLikeVariables
  >(M_POST_UNLIKE, {
    onCompleted: (data) => {
      if (!data.delete_liked_posts_by_pk) return
      const likedPost = data.delete_liked_posts_by_pk as LikedPosts
      const { liked_profiles_connections, ...newPost } =
        parseLikedPost(likedPost)

      updatePostFromCache(newPost)
    },
  })

  enum TypeOfPost {
    REGULAR = 'DISCUSSION',
    REFERRAL = 'REFERRAL',
    NEWS = 'NEWS',
    BLOG = 'BLOG',
  }

  const category =
    post.__typename == 'opportunities'
      ? TypeOfPost.REFERRAL
      : 'post_category' in post
      ? post?.post_category?.type
      : TypeOfPost.REGULAR

  const comments = postCommentsData?.post_comments || []

  const handleSharePost = () => {
    const { pathname, query } = router
    router.push(
      { pathname, query: { share_post: true, post_id: post.id, ...query } },
      undefined,
      {
        shallow: true,
        scroll: false,
      },
    )
  }

  const onLike = () => {
    if (isLiked) {
      setIsLiked(false)
      setLikes(whether(likes > 1, likes - 1, 0))
      unlikePostMutation({
        variables: {
          postId: post.id,
          fromProfileId: reader.id,
        },
      })
    } else {
      setIsLiked(true)
      setLikes(likes + 1)
      likePostMutation({
        variables: {
          postId: post.id,
          fromProfileId: reader.id,
        },
      })
    }
  }

  const postType = {
    [TypeOfPost.REGULAR]: (
      <RegularPost
        post={post as ConnectionsPosts | BusinessPosts}
        likes={likes}
        isOnFeed
        onLike={onLike}
        disabledLikeButton={likeMutationLoading || unlikeMutationLoading}
        onShare={handleSharePost}
        isLiked={isLiked || false}
        comments={comments}
      />
    ),
    [TypeOfPost.REFERRAL]: <ReferralPost post={post as Opportunities} />,
    [TypeOfPost.NEWS]: (
      <NewsPost
        post={post as ConnectionsPosts | BusinessPosts}
        likes={likes}
        onLike={onLike}
        disabledLikeButton={likeMutationLoading || unlikeMutationLoading}
        onShare={handleSharePost}
        isLiked={isLiked || false}
        comments={comments}
      />
    ),
    [TypeOfPost.BLOG]: (
      <NewsPost
        post={post as ConnectionsPosts | BusinessPosts}
        likes={likes}
        onLike={onLike}
        disabledLikeButton={likeMutationLoading || unlikeMutationLoading}
        onShare={handleSharePost}
        isLiked={isLiked || false}
        comments={comments}
      />
    ),
  }

  return postType[category as keyof typeof postType]
}
