import { useState } from 'react'

import { TrackedFile } from 'components/upload'

import { onImageUploadStatusChange } from 'utils/posts/images'

const useImageUpload = () => {
  const [trackedFiles, setTrackedFiles] = useState<TrackedFile[]>()

  const handleImageUpload = (file: TrackedFile) => {
    const newTrackedFiles = onImageUploadStatusChange(trackedFiles, file)
    setTrackedFiles(newTrackedFiles)
  }

  return {
    trackedFiles,
    handleImageUpload,
  }
}

export default useImageUpload
